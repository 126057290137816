<template>
  <div class="loader">
    <div class="one" />
    <div class="two" />
  </div>
</template>

<script>
import { mapActions } from 'vuex';

export default {
  name: 'PilotLogin',
  async mounted() {
    await this.doLogin();
  },
  methods: {
    ...mapActions({
      doLoginFeedback: 'authentication/doLoginFeedback',
      activateModal: 'utils/activateModal',
      setFeedbackId: 'feedback/setFeedbackId',
    }),
    async doLogin() {
      const { uuid, interventionId, status } = this.$route.params;

      try {
        await this.doLoginFeedback({ uuid });
        this.$router.push({ name: 'Dashboard', query: { uuid, status } });
        this.activateModal({ name: 'Feedback' });
        if (interventionId) this.setFeedbackId(interventionId);
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error('error login with uuid', uuid);
      }
    },
  },
};
</script>
<style scoped>
.loader {
  display: flex;
  height: 100vh;
  width: 100%;
  justify-content: center;
  align-items: center;
  position: relative;
  background: var(--neutral-50);
}

.one {
  position: absolute;
  border-radius: 50%;
  background: var(--primary-medium);
  opacity: .0;
  animation: loading 1.3s .65s infinite;
  height: 20px;
  width: 20px;
}

.two {
  position: absolute;
  border-radius: 50%;
  background: var(--secondary-dark);
  opacity: .0;
  animation: loading 1.3s infinite;
  height: 20px;
  width: 20px;
}

@keyframes loading {
  0% {
    opacity: .0;
    transform: scale(.15);
    box-shadow: 0 0 2px rgba(black, .1);
  }
  50% {
    opacity: 1;
    transform: scale(2);
    box-shadow: 0 0 10px rgba(black, .1);
  }
  100% {
    opacity: .0;
    transform: scale(.15);
    box-shadow: 0 0 2px rgba(black, .1);
  }
}

</style>
